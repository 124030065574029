<template>
  <div class="questionnaire-list-box" v-wechat-title="'最新调研'">
    <div class="questionnaire-list-banner">
      <img src="@/assets/img/wjbanner.png" alt="问卷banner"/>
    </div>
    <div class="questionnaire-list-content">
      <div class="questionnaire-list-desc">
        <p><span></span> 调研说明</p>
        <div>
          中软国际作为开放原子基金会开源鸿蒙项目的白金捐赠人，正在联合政府工信和大数据等主管部门做自主创新替代迁移及数字化转型工作。鸿联创作为中软国际政府组织侧的牵头组织，为了配合政府主管部门深入了解区域企业生产经营情况，实时掌握企业在生产经营过程中遇到的难点痛点，诚邀请（贵司）配合调研，便于政府主管部门有针对性的制定相应的企业福利政策，推动各地自主创新和数字化转型产业生态发展。
        </div>
      </div>
      <div class="questionnaire-list-ad">
        <p>
          <img src="@/assets/img/wjtitlebg.png" alt="调研动态"/>
          <span>调研动态</span>
        </p>
        <div>
          <van-notice-bar
            :scrollable="false"
            color="#333333"
            background="#ffffff"
          >
            <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :show-indicators="false"
            >
              <van-swipe-item
                v-for="(item, index) in responseListData"
                :key="index"
              >{{ item.enrolledTime | moment('HH:mm') }}
                {{ item.contact.substring(0, 3) }}****{{
                  item.contact.substring(7, 11)
                }}
                {{ item.questionnaireName }}
              </van-swipe-item
              >
            </van-swipe>
          </van-notice-bar>
        </div>
      </div>
      <van-list
        v-if="list.length>0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="questionnaire-list">
          <template v-for="(item, i) in list">
            <div :key="i" @click="questionnaireInfo(item.id)">
              <div>
                <span><img src="@/assets/img/wjlb.png" alt="标签"/></span>
                <v-img
                  :attachment-id="item.questionnaireCoverAttmId"
                  :default-img="require('@/assets/img/wgtp.png')"
                />
              </div>
              <span>{{ item.title }}</span>
              <p>
                {{ item.fillTotal + 20 }}人已参与
                <span v-if="item.tenantNames">{{ item.tenantNames[0] }}</span>
              </p>
            </div>
          </template>
        </div>
      </van-list>

      <van-empty
        v-if="emptybool"
        class="custom-image"
        :image="nonecontent"
        description="暂无内容"
      />

    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { responseList, getQuestionnaireList } from '@/api/questionnaire'
import VImg from '@/components/VImg'
import nonecon from '@/assets/img/nonecontent1.png'
import share from '../components/share.vue'

export default {
  components: {
    VImg,
    share
  },
  data () {
    return {
      nonecontent: nonecon,
      emptybool: false,
      responseListData: '',
      page: {
        current: 1,
        size: 10,
        pageSize: 1,
      },
      list: [],
      loading: false,
      finished: false,
      shareInfo: {
        title: '最新调研',
        desc: '中软国际作为开放原子基金会开源鸿蒙项目的白金捐赠人，正在联合政府工信和大数据等主管部门做自主创新替代迁移及数字化转型工作。鸿联创作为中软国际政府组织侧的牵头组织，为了配合政府主管部门深入了解区域企业生产经营情况，实时掌握企业在生产经营过程中遇到的难点痛点，诚邀请（贵司）配合调研，便于政府主管部门有针对性的制定相应的企业福利政策，推动各地自主创新和数字化转型产业生态发展',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553280988069548034`
      }
    }
  },
  mounted () {
    console.log(this.$router)
    this.loadresponseList()
    this.loadgetQuestionnaireList()
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },
  methods: {
    loadresponseList () {
      responseList({}).then((res) => {
        console.log(res)
        this.responseListData = res.records
      })
    },
    onLoad () {
      if (this.page.pageSize == this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadgetQuestionnaireList()
    },
    loadgetQuestionnaireList () {
      let { list } = this
      getQuestionnaireList(this.page, { orderField: 'publish_time' }).then((res) => {
          this.loading = false
          console.log(res)
          this.list = [...res.records, ...list]
          this.page.pageSize = res.pages
          if (res.pages <= res.current) {
            this.finished = true
            return
          }
          this.finished = false

          if (this.List.length == 0) {
            this.emptybool = true
          } else {
            this.emptybool = false
          }

        }
      )
    },
    questionnaireInfo (id) {
      this.$router.push({
        path: '/questionnaire/info',
        query: {
          id,
          userid: ''
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-image {
  ::v-deep {
    .van-empty__image {
      width: 114px;
      height: 74.09px;
    }

    .van-empty__description {
      font-size: 14px;
      font-weight: 500;
      line-height: 46px;
      color: #999999;
    }
  }
}

.questionnaire-list-box {
  min-height: 100%;
  background: #f6f6f6;

  .questionnaire-list-banner {
    height: 210px;

    > img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .questionnaire-list-content {
    position: relative;
    z-index: 2;
    margin-top: -98px;
    padding: 0 16px;

    .questionnaire-list-desc {
      padding: 16px;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 30px;

      > p {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        margin-bottom: 12px;
        line-height: 24px;

        > span {
          width: 2px;
          height: 16px;
          background: #EA0B06;
          margin-right: 8px;
        }
      }

      > div {
        font-size: 12px;
        color: #666;
        line-height: 18px;
        text-align: justify;
      }
    }

    .questionnaire-list-ad {
      display: flex;
      height: 24px;
      background: #ffffff;
      border-radius: 12px;
      position: relative;
      padding-left: 65px;

      > p {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: 0;
        height: 29px;
        width: 65px;

        > img {
          width: 100%;
          height: 100%;
          display: block;
        }

        > span {
          position: absolute;
          z-index: 2;
          display: block;
          line-height: 22px;
          font-size: 12px;
          color: #fff;
          left: 6px;
          right: 0;
          top: 0;
        }
      }

      > div {
        flex: 1;
      }
    }

    .questionnaire-list {
      padding: 24px 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: 167px;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        padding-bottom: 10px;
        margin-bottom: 10px;

        > div {
          position: relative;
          height: 74px;

          > span {
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            left: 4px;
            top: 4px;
            display: none;

            > img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        > span {
          display: block;
          margin: 8px 0;
          padding: 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          line-height: 20px;
        }

        > p {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          margin-bottom: 0;
          color: #666;
          padding: 0 12px;

          > span {
            height: 19px;
            background: #f4f8f7;
            border-radius: 2px;
            padding: 0 6px;
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          > div {
            > span {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.questionnaire-list-ad .van-notice-bar {
  height: 100%;
  border-radius: 12px;
  padding: 0 9px;
}

.questionnaire-list-ad .notice-swipe {
  height: 24px;
  line-height: 24px;
}

.questionnaire-list-ad .van-swipe-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
